// 背景色に適切な文字色を返す
export const contrastTextColor = (currentColor: string) => {
  const rgb = hexToRgb(currentColor)
  const brightness = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255
  return brightness < 0.5 ? "#fff" : "#000"
}

/**
 * #ffffff -> { r: 255, g: 255, b: 255 } のように変換する
 * @param hex #ffffff形式の16進数カラーコード
 * @returns r, g, bの各値は0~255の整数
 */
export const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return { r, g, b }
}
