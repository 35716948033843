<template>
  <transition :enter-active-class="$style.enter_active" :leave-active-class="$style.leave_active">
    <div v-if="modelValue" :class="[$style.modal, snapToEdgeClass, pcSnapToEdgeClass]">
      <div :class="$style.overlay" @click="onClickOverlay" />
      <div :class="$style.content" :style="{ 'max-width': maxWidth, 'min-width': minWidth, 'min-height': minHeight }">
        <AtomsConsumerButton v-if="isDisplayCloseButton" :class="$style.close" @click="onClickClose" />
        <div
          :class="[$style.body, { [$style.visible_button]: isDisplayBottomButton }]"
          :style="{ 'max-height': maxHeight }"
        >
          <slot />
        </div>
        <div v-if="isDisplayBottomButton" :class="$style.button_area">
          <slot name="button" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, onUnmounted, watch, computed, useCssModule, onMounted } from "vue"
import { hexToRgb } from "@tential/ec-gql-schema/utils/color"
const style = useCssModule()

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    /** 右上の閉じるボタンを表示するか */
    isDisplayCloseButton?: boolean
    /** オーバーレイクリックで閉じるか */
    isDisabledOverlay?: boolean

    /**
     * モーダル背景色
     * - default: #284b7d($primary)
     * */
    overlayBackgroundColorHex?: string
    minWidth?: string
    maxWidth?: string
    minHeight?: string
    maxHeight?: string
    isDisplayBottomButton?: boolean
    /**
     * 画面の上下左右にスナップする (snapToEdge指定でSP/PCに適用、pcSnapToEdge指定でPCのみに適用)
     * - default: center
     * */
    snapToEdge?: "top" | "bottom" | "center" | "left" | "right"
    pcSnapToEdge?: "top" | "bottom" | "center" | "left" | "right"
    /**
     * ブラウザバックでモーダルを閉じる
     * - default: true
     * - ブラウザバックによるモーダルを行わない or モーダルをネストさせる場合は false を指定
     * - 変更検知は行われない（初回指定した値が反映される）
     */
    enableBrowserBackClose?: boolean
  }>(),
  {
    modelValue: false,
    isDisplayCloseButton: true,
    isDisabledOverlay: false,
    overlayBackgroundColorHex: "#284b7d",
    minWidth: undefined,
    maxWidth: undefined,
    minHeight: undefined,
    maxHeight: undefined,
    isDisplayButton: false,
    snapToEdge: "center",
    pcSnapToEdge: "center",
    enableBrowserBackClose: true,
  },
)

const onceEnableBrowserBackClose = !!props.enableBrowserBackClose

const router = useRouter()
const route = useRoute()
const scrollY = ref(0)

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: boolean): void
}>()

const snapToEdgeClass = computed(() => {
  return props.snapToEdge ? style[props.snapToEdge] : ""
})

const pcSnapToEdgeClass = computed(() => {
  return props.pcSnapToEdge ? style[`pc-${props.pcSnapToEdge}`] : ""
})

const overlayBackgroundColorRGBA = computed(() => {
  const { r, g, b } = hexToRgb(props.overlayBackgroundColorHex)
  return `rgba(${r}, ${g}, ${b}, 0.85)`
})

const onClickOverlay = (): void => {
  if (!props.isDisabledOverlay) close()
}

const onClickClose = (): void => {
  close()
  if (onceEnableBrowserBackClose) {
    router.go(-1)
  }
}

const close = (): void => {
  if (props.modelValue) emit("update:modelValue", false)
}

if (onceEnableBrowserBackClose) {
  watch(
    () => props.modelValue,
    (value) => {
      if (value) {
        window.history.pushState(null, "", null)
        // Modalを開いた時に、スクロール位置を記憶してモーダル裏でスクロールができないようにする
        scrollY.value = window.scrollY
        window.document.body.style.position = "fixed"
        window.document.body.style.top = -1 * scrollY.value + "px"
        window.document.body.style.width = "100%"
        // TODO: /bakune/cmでheaderにz-indexで勝てないので下記で対応。もう少し良いやり方あるかも
        if (route.path === "/bakune/cm") {
          const headerElement = document.getElementById("site-header-id")
          if (headerElement) {
            headerElement.style.display = "none"
            headerElement.style.opacity = "0"
          }
        }
      } else {
        // Modalを閉じた時に、スクロール位置を元に戻す
        window.document.body.style.position = ""
        if (scrollY.value) window.scrollTo(0, scrollY.value)
        // TODO: /bakune/cmでheaderにz-indexで勝てないので下記で対応。もう少し良いやり方あるかも
        if (route.path === "/bakune/cm") {
          const headerElement = document.getElementById("site-header-id")
          if (headerElement) {
            headerElement.style.display = "block"
            headerElement.style.opacity = "1"
          }
        }
      }
    },
  )

  onMounted(() => {
    window.addEventListener("popstate", close)
  })

  onUnmounted(() => {
    window.removeEventListener("popstate", close)
    window.document.body.style.position = ""
    if (scrollY.value) window.scrollTo(0, scrollY.value)
  })
}
</script>

<style scoped module lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  display: flex;
  &.bottom {
    align-items: flex-end;
    justify-content: center;
    .content {
      border-radius: 1rem 1rem 0 0;
    }
  }
  &.top {
    align-items: flex-start;
    justify-content: center;
    .content {
      border-radius: 0 0 1rem 1rem;
    }
  }
  &.left {
    align-items: center;
    justify-content: flex-start;
    .content {
      border-radius: 0 1rem 1rem 0;
    }
  }
  &.right {
    align-items: center;
    justify-content: flex-end;
    .content {
      border-radius: 1rem 0 0 1rem;
    }
  }
  &.center {
    align-items: center;
    justify-content: center;
    .content {
      border-radius: 1rem;
    }
  }
  @include md {
    &.pc-bottom {
      align-items: flex-end;
      justify-content: center;
      .content {
        border-radius: 1rem 1rem 0 0;
      }
    }
    &.pc-top {
      align-items: flex-start;
      justify-content: center;
      .content {
        border-radius: 0 0 1rem 1rem;
      }
    }
    &.pc-left {
      align-items: center;
      justify-content: flex-start;
      .content {
        border-radius: 0 1rem 1rem 0;
      }
    }
    &.pc-right {
      align-items: center;
      justify-content: flex-end;
      .content {
        border-radius: 1rem 0 0 1rem;
      }
    }
    &.pc-center {
      align-items: center;
      justify-content: center;
      .content {
        border-radius: 1rem;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: v-bind(overlayBackgroundColorRGBA);
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 151;
    background-color: $white;
    .close {
      position: absolute;
      z-index: 1;
      top: 1rem;
      right: 1rem;
      padding: 16px;
      cursor: pointer;
      @include md {
        top: 2rem;
        right: 2rem;
      }
      &::before,
      &::after {
        position: absolute;
        top: 1rem;
        left: 0px;
        width: 1.5rem;
        height: 1px;
        content: "";
        background: $primary;
        @include md {
          width: 2rem;
        }
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
    .body {
      max-width: 100vw;
      max-height: 100vh;
      max-height: 100dvh;
      overflow: auto;
      padding: 1rem;
      &.visible_button {
        padding-bottom: 2.5rem;
      }
      @include md {
        padding: 3rem;
      }
    }
    .button_area {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.enter_active {
  animation: fade 0.2s;
}
.leave_active {
  animation: fade 0.2s linear reverse;
}
</style>
